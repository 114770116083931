<template data-testid="tours">
  <v-container v-if="!loadingStore.isGlobalLoading">
    <v-row class="pt-4">
      <v-col>
        <span class="login-title"> {{ pageTitle }}</span>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col cols="4" class="d-flex justify-left align-end">
        <IconButton
          class="icon-btn background-white height-40 width-40"
          icon="mdi-arrow-left"
          :border="false"
          @click="previousDay()"
          data-testid="tours-previous-day-btn"
          :disabled="disableButtons"
        />
        <DatePicker
          class="d-flex ml-2 height-40"
          v-model="toursStore.selectedDate"
          :max="maxDate"
          hide-details
          @change="updateTours()"
          :disabled="disableButtons"
        />
        <IconButton
          class="icon-btn ml-2 background-white height-40 width-40"
          icon="mdi-arrow-right"
          :border="false"
          data-testid="tours-next-day-btn"
          @click="nextDay()"
          :disabled="disableNextButton"
        />
      </v-col>
      <v-col cols="3">
        <DropdownInput
          data-testid="tours-select-hub-dropdown"
          label=""
          @change="setHub($event)"
          hide-details
          :value="toursStore.selectedHub"
          :items="toursStore.hubList"
          :clearable="false"
          item-text="name"
          item-value="id"
          :disabled="
            toursStore.hubList.length <= 1 || loadingStore.isPartialLoading
          "
        />
      </v-col>
    </v-row>
    <div v-if="toursStore.hubIsEligible">
      <router-view></router-view>
    </div>
    <div v-else class="mt-4">
      <v-card class="rounded-lg shadow-regular">
        <v-card-text>
          <WarningAlert :text="t('Msg_.mainHubNotEligible')" />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script setup>
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useMainStore } from '@/store/pinia/main.store';
import { useRoute } from 'vue-router/composables';
import { useToursStore } from '@/store/pinia/tours.store.js';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import WarningAlert from '@/components/shared/customComponents/alertsBadges/warning-alert.vue';
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';

import languages from './locales/languages';

const route = useRoute();
const loadingStore = useLoadingStore();
const toursStore = useToursStore();
const mainStore = useMainStore();
const { trackPage } = amplitudeComposable();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local Variables ---
const today = ref();
const yesterday = ref();

// --- Methods ---
const getToursInformation = async () => {
  setInitialDate();
  setErrorMessage();
  await toursStore.getHubList();
  await updateTours();
  toursStore.tourDataInitialized = true;
};

const setInitialDate = () => {
  today.value = todayDate();
  yesterday.value = yesterdayDate();
  if (isComparisonPage.value) {
    toursStore.selectedDate = yesterday.value;
  } else {
    toursStore.selectedDate = today.value;
  }
};

const todayDate = () => {
  return new Date().toISOString().substring(0, 10);
};

const yesterdayDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date.toISOString().substring(0, 10);
};

const nextDay = async () => {
  const nextDay = new Date(toursStore.selectedDate);
  nextDay.setDate(nextDay.getDate() + 1);
  toursStore.selectedDate = nextDay.toISOString().substring(0, 10);
  await updateTours();
};

const previousDay = async () => {
  const previousDay = new Date(toursStore.selectedDate);
  previousDay.setDate(previousDay.getDate() - 1);
  toursStore.selectedDate = previousDay.toISOString().substring(0, 10);
  await updateTours();
};

const updateTours = async () => {
  if (isComparisonPage.value) {
    await toursStore.getToursComparison();
  } else {
    await toursStore.getTours();
  }
  trackPage(toursStore.selectedHub, {
    resourceId: toursStore.selectedDate,
  });

  scrollToSelectedTourCard();
};

const scrollToSelectedTourCard = () => {
  const toursContainerElement = document.getElementById('tours-container');
  const selectedTour = isComparisonPage.value
    ? toursStore.selectedComparisonTour
    : toursStore.selectedTour;
  const selectedTourCardElement = document.getElementById(
    `tour-${selectedTour}`
  );

  const paddingTop = 12; // padding to align the scrolled card with the tour details card

  if (toursContainerElement && selectedTourCardElement) {
    const toursContainerBoundingRect =
      toursContainerElement.getBoundingClientRect();

    const cardBoundingRect = selectedTourCardElement.getBoundingClientRect();

    toursContainerElement.scrollTo(
      cardBoundingRect?.left,
      cardBoundingRect?.top - (toursContainerBoundingRect?.top + paddingTop)
    );
  }
};

const setHub = async (hub) => {
  toursStore.setHub(hub);
  await updateTours();
};

const setErrorMessage = () => {
  mainStore.errorPageMessage = t('Msg_.errorToursInformation');
};

// --- Computed ---
const isComparisonPage = computed(() => {
  return route.path.includes('tour-comparison');
});

const maxDate = computed(() => {
  return isComparisonPage.value ? yesterday.value : null;
});

const disableButtons = computed(() => {
  return !toursStore.hubIsEligible || loadingStore.isPartialLoading;
});

const disableNextButton = computed(() => {
  const disableNext =
    isComparisonPage.value && toursStore.selectedDate >= maxDate.value;
  return disableButtons.value || disableNext;
});

const pageTitle = computed(() => {
  return isComparisonPage.value
    ? t('Title_.tourComparison')
    : t('Title_.tourInformation');
});

// --- Watchers ---
watch(
  () => route.path,
  () => {
    setInitialDate();
    updateTours();
  }
);

// --- Lifecycle hooks ---
getToursInformation();

onBeforeUnmount(() => {
  toursStore.tourDataInitialized = false;
});
</script>
<style lang="scss" scoped>
.blue-regular {
  color: $blue-regular;
}

.background-white {
  background-color: #fff;
}

.height-40 {
  height: 40px !important;
}

.width-40 {
  width: 40px !important;
}
</style>
