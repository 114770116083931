import { render, staticRenderFns } from "./tours.vue?vue&type=template&id=cb23ba2a&scoped=true&data-testid=tours&"
import script from "./tours.vue?vue&type=script&setup=true&lang=js&"
export * from "./tours.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./tours.vue?vue&type=style&index=0&id=cb23ba2a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb23ba2a",
  null
  
)

export default component.exports